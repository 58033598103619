<template>
  <div style="border: 1px solid #ccc; line-height: 1.5">
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor
      v-model="valueHtml"
      style="height: 600px; overflow-y: hidden"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { ossUpload } from '@/service/oss'

export default Vue.extend({
  components: { Editor, Toolbar },
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '请输入内容...',
    },
  },
  data() {
    return {
      editor: null,
      valueHtml: undefined,
      toolbarConfig: {
        excludeKeys: [],
      },
      editorConfig: {
        placeholder: this.placeholder,
        autoFocus: false,

        MENU_CONF: {
          insertImage: {
            checkImage: (src) => {
              if (src.indexOf('http') !== 0) {
                this.$message.error('图片网址必须以 http/https 开头')
                return ''
              }
              return true
            },
          },
          uploadImage: {
            customUpload: async (file, insertFn) => {
              console.log('customUpload', file)
              const src = await ossUpload(file)
              insertFn(src, '', src)
            },
          },
        },
      },
      mode: 'simple', // or 'simple'
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    onChange() {
      this.$emit('change', this.editor.getHtml())
    },
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  watch: {
    value: {
      immediate: true,
      async handler(v) {
        if (!this.editor) {
          await this.$nextTick()
        }
        this.valueHtml = v
      },
    },
  },
})
</script>
<style>
.w-e-text-container [data-slate-editor] .w-e-selected-image-container {
  overflow: unset;
}
</style>
